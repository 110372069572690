<template>
  <main>
    <h1 class="headline blue--text text-center mt-4 mb-3">Chat-viestien seuranta</h1>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-autocomplete
            v-model="selected_agent"
            :search-input.sync="search_agent"
            :items="agents_list"
            item-value="agent_id"
            item-text="name"
            label="Etsi agentti"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="selected_alias"
            :search-input.sync="search_alias"
            :items="alias_list"
            :return-object="false"
            item-value="id"
            item-text="name"
            label="Etsi alias"
            clearable
        >
          <template #item="{ item }">
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-radio-group v-model="selected_type" >
          <v-radio value=1 label="Viihde"/>
          <v-radio value=2 label="Asiantuntija"/>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
      </v-col>
        <v-col cols="2">
            <v-text-field v-model="consumer_phone" placeholder="0401234567" label="Asiakkaan puhelinnumero"></v-text-field>
        </v-col>
        <v-col cols="1">
            <v-btn
                    class="v-btn green float-right"
                    @click="getResource"
                    small
            >
                HAE
            </v-btn>
        </v-col>
    </v-row>
      <v-card class="mt-8" v-if="resources.length > 0 && !resourceLoading">
          <v-card-text>
              <v-simple-table>
                  <thead>
                  <tr>
                      <td>Aika</td>
                      <td>Suunta</td>
                      <td>Käsitelty</td>
                      <td>Agentti</td>
                      <td>Alias</td>
                      <!--<td>Ryhmä</td>-->
                      <td>A. Nimi</td>
                      <td>Lähettäjä</td>
                      <td>Vastaanottaja</td>
                      <td>Viesti</td>
                      <td>Vastaus</td>
                      <td>Netchat room</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="r in resources" :key="r.id">
                      <td>{{ r.transaction.received_date }}</td>
                      <td>{{ r.transaction.chat_message_state }}</td>
                      <td>{{ r.transaction.sent_date }}</td>
                      <td>{{ r.transaction.agent_name }}</td>
                      <td>{{ r.transaction.alias_name }}</td>
                      <!--<td>{{ r.transaction.keyword1 }}</td>-->
                      <td>{{ r.transaction.chat_user_name }}</td>
                      <td>{{ r.transaction.consumer_msisdn }}</td>
                      <td>{{ r.transaction.service_number }}</td>
                      <td>{{ r.transaction.received_payload }}</td>
                      <td>{{ r.transaction.sent_payload }}</td>
                      <td>{{ r.transaction.chat_room_id }}</td>
                  </tr>
                  </tbody>


              </v-simple-table>
          </v-card-text>
      </v-card>
      <v-card v-if="resourceLoading">
          <v-card-title>
              Ladataan...
          </v-card-title>
      </v-card>
  </main>
</template>

<script>
export default {
  name: "ChatMessagesReport",
  data: function () {
    return {
      startDate: null,
      endDate: null,
      selected_agent: null,
      selected_alias: null,
      agents_list: [],
      alias_list: [],
      selected_type: "1",
      search_agent: "",
      search_alias: "",
        resources: [],
      resourceLoading: false,
      consumer_phone: null,
    }
  },
  watch: {
      /*
    search_agent(){
      if(this.search_agent){
        this.loading = true
        this.axios.get('agents', {
          params: {
            skip: 0,
            take: 30,
            order: 'firstname',
            order_direction: 'DESC',
            keyword: this.search_agent
          }
        }).then((response)=>{
          if(response.data.length > 0)this.agents_list = response.data
          this.loading = false
        })
      }
    },

       */
    search_alias(){
      if(this.search_alias){
        this.loading = true
        this.axios.get('chat_aliases', {
          params: {
            skip: 0,
            take: 30,
            order: 'created_at',
            order_direction: 'DESC',
            keyword: this.search_alias,
            type: this.selected_type
          }
        }).then((response)=>{
          this.alias_list = response.data
          this.loading = false
        })
      }
    },
  },
  methods: {

      async getResource(){
          if(this.resourceLoading) return
          this.resourceLoading = true
          this.resource = null
          await this.axios.post('reports/chat_messages', {
              from: this.startDate,
              to: this.endDate,
              type: Number(this.selected_type),
              agent_id: this.selected_agent,
              alias_id: this.selected_alias,
              mode: this.selectedResourceSelector,
              consumer_phone: this.consumer_phone
          })
              .then((resources) => {
                  this.resources = resources.data
              })
              .catch ((error) => {console.error(error);});
          this.resourceLoading = false
      },

  },
  mounted() {
    this.startDate = this.$day().format("DD.MM.YY")
    this.endDate = this.$day().format("DD.MM.YY")
    //this.getResource()
    this.axios.get('reports/agent_list', {
      params: {
        feature: ['2','4'],
      }
    }).then((r) => {
      this.agents_list = r.data
    }) //get agents_list

      /* testing:
      setInterval(() => {
          this.axios.get('reports/monitoring/current').then((r) => {
              console.log(r.data)
          }) //get agents_list
      }, 30000)

       */
  }
}
</script>

<style scoped>

</style>